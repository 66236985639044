<template>
  <div class="container" v-if="$i18n.locale == 'en'">
    <div class="row">
      <div class="col s12 left-align">
        <h1>MetaMask connected to Binance Smart Chain</h1>
        <h2>
          Connecting MetaMask to Binance  Smart Chain you can operate on the TMPR, Trademark Public Register
        </h2>
        <p>In this video we’ll quickly explain how to install MetaMask and connect it ti the Binance Smart Chain so you can interact on the TMPR, the Trademark Public Register.</p>
        <ul>
          <li>
            <h5>Installing and Configuring MetaMask</h5>
            <p>In the first place, you have to scroll down to the MetaMask <a href="http://https://metamask.io/download" target="_blank">download page</a>. Once you’re there, elect the platform that you’re using, and follow the steps.</p>
            <p>Following that, go to the installation specified by the app. Keep going and click on “Create a Wallet”.</p>
            <p>And that’s it! Now we have to be able to see your wallet, ready to send and receive funds. </p>
            <p v-if="$i18n.locale == 'en'">
              <iframe width="760" height="415" id="video" src="https://www.youtube.com/embed/99JFQuvM6M4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>
            <p v-else-if="$i18n.locale == 'es'">
              <iframe width="760" height="415" src="https://www.youtube.com/embed/Bj0_Kyp45Jc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>
            <p v-else>
              <iframe width="760" height="415" src="https://www.youtube.com/embed/8hS2BEunE4E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>
          </li>
          <li>
            <h5>Configuring the wallet </h5>
            <p>We’ll have to access Settings to aim the wallet toward the Binance Smart Chain’s nodes.</p>
            <p>On the “Settings” page, we have to locate the “Networks” menu.</p>
            <p>We have to click on Add Network in the upper right-hand corner, so we manually add the Binance Smart Chain – since it hasn’t been integrated with MetaMask. There are two networks we can use: the Testnet or the Mainnet.</p>
          </li>
          <li>
            <h5>Mainnet (This is probably the one you’re going to look for)</h5>
            <p>Network name: Smart Chain</p>
            <p>New RPC URL: https://bsc-dataseed.binance.org/</p>
            <p>ChainID: 56</p>
            <p>Symbol: BNB</p>
            <p>Block Explorer URL: https://bscscan.com</p>
          </li>
          <li>
            <h5>Testnet</h5>
            <p>Network name: Smart Chain Testnet</p>
            <p>New RPC URL: https://data-seed-prebsc-1-s1.binance.org:8545/</p>
            <p>ChainID: 97</p>
            <p>Symbol: BNB</p>
            <p>Block Explorer URL: https://testnet.bscscan.com</p>
          </li>
        </ul>
        <p><strong>We recommend adding both if you hope to use MetaMask for transferring BNB or tokens from the Binance Smart Chain.</strong></p>
        <p>Once you have saved the network and return to the main view, take note of two things: the network has been automatically configured and what you enter are no longer named BNB. </p>
      </div>
    </div>
  </div>
  <div class="container"  v-if="$i18n.locale == 'it'">
    <div class="row">
      <div class="col s12 left-align">
        <h1>Collegare MetaMask alla smart chain di Binance</h1>
        <h2>
          Come collegare MetaMask a  Binance Smart Chain per poter registrare il tuo marchio nel TMPR, il Registro Pubblico dei Marchi?
        </h2>
        <p>In questo video spiegheremo rapidamente come installare e collegare MetaMask  alla smart chain di  Binance per poter registrare il tuo marchio nel TMPR.</p>
        <ul>
          <li>
            <h5>Installare e configurare MetaMask</h5>
            <p>Per prima cosa, dovrai navigare alla <a href="http://https://metamask.io/download" target="_blank">"pagina di download</a> di MetaMask. Una volta lì, seleziona la piattaforma che stai usando e segui i passi.</p>
            <p>Poi, continua con l'installazione specificata dall'applicazione. Vai avanti e clicca su "Crea un wallet".</p>
            <p>E questo è tutto! Ora dovresti essere in grado di vedere il tuo portafoglio, pronto per inviare e ricevere fondi.</p>
            <p v-if="$i18n.locale == 'en'">
              <iframe width="760" height="415" id="video" src="https://www.youtube.com/embed/99JFQuvM6M4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>
            <p v-else-if="$i18n.locale == 'es'">
              <iframe width="760" height="415" src="https://www.youtube.com/embed/Bj0_Kyp45Jc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>
            <p v-else>
              <iframe width="760" height="415" src="https://www.youtube.com/embed/8hS2BEunE4E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>
          </li>
          <li>
            <h5>Configurare il wallet</h5>
            <p>Avremo bisogno di accedere alle Impostazioni per puntare il portafoglio ai nodi della Smart Chain di Binance.</p>
            <p>Nella pagina "Setting" (configurazione), trova il menu "Networks".</p>
            <p>Clicca su Add Network (Aggiungi rete) in alto a destra per aggiungere manualmente la rete Binance Smart Chain - dato che non è integrata con MetaMask.</p>
          </li>
          <li>
            <h5>Utilizziamo la rete Mainnet</h5>
            <p>Nome della rete: Smart Chain</p>
            <p>Nuovo URL RPC: https://bsc-dataseed.binance.org/</p>
            <p>ChainID: 56</p>
            <p>Symbol: BNB</p>
            <p>Block Explorer URL: https://bscscan.com</p>
            <p>Una volta salvata la rete e tornato alla vista principale, noterai due cose: la rete è stata automaticamente impostata su quella che hai appena inserito, e le unità non sono più denominate in ETH, ma in BNB.</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container" v-if="$i18n.locale == 'es'">
    <div class="row">
      <div class="col s12 left-align">
        <h1>Como conectar MetaMask a Binance Smart Chain</h1>
        <h2>
          Conectando MetaMask a Binance Smart Chain podrás operar en el TMPR, Registro Público de Marcas
        </h2>
        <p>En este video te explicamos rápidamente como instalar y conectar  MetaMask a Binance Smart Chain para poder interagir en el TMPR, Registro Público de Marcas</p>
        <ul>
          <li>
            <h5>Instalar y configurar MetaMask</h5>
            <p>En primer lugar, deberás desplazarte hasta la <a href="http://https://metamask.io/download" target="_blank">página de descarga</a> de MetaMask. Una vez allí, selecciona la plataforma que estás utilizando, y sigue los pasos.</p>
            <p>A continuación, continúa con la instalación especificada por la app. Sigue adelante y clica en "Create a Wallet" (crear un monedero).</p>
            <p>¡Y eso es todo! Ahora deberías poder ver tu monedero, listo para enviar y recibir fondos.</p>
            <p v-if="$i18n.locale == 'en'">
              <iframe width="760" height="415" id="video" src="https://www.youtube.com/embed/99JFQuvM6M4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>
            <p v-else-if="$i18n.locale == 'es'">
              <iframe width="760" height="415" src="https://www.youtube.com/embed/Bj0_Kyp45Jc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>
            <p v-else>
              <iframe width="760" height="415" src="https://www.youtube.com/embed/8hS2BEunE4E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>
          </li>
          <li>
            <h5>Configurar el monedero</h5>
            <p>Deberemos acceder a Settings (ajustes) para apuntar el monedero hacia los nodos de Binance Smart Chain.</p>
            <p>En la página de "Settings" (ajustes), debemos localizar el menú de "Networks" (redes).</p>
            <p>Deberemos clicar en Add Network (añadir red) en la esquina superior derecha, para así añadir manualmente la de Binance Smart Chain –ya que no viene integrada con MetaMask. Existen dos redes que podremos utilizar: la testnet o la mainnet.</p>
          </li>
          <li>
            <h5>Mainnet (ésta es probablemente la que andes buscando)</h5>
            <p>Network name: Smart Chain</p>
            <p>New RPC URL: https://bsc-dataseed.binance.org/</p>
            <p>ChainID: 56</p>
            <p>Symbol: BNB</p>
            <p>Block Explorer URL: https://bscscan.com</p>
            <p>Una vez que guardes la red y regreses a la vista principal, notarás dos cosas: la red se ha configurado automáticamente a la que acabas de ingresar, y las unidades ya no están denominadas en ETH, sino en BNB.</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
